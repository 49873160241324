export default defineNuxtRouteMiddleware(async (to) => {
  const { projects, singleProject } = useProjectStore();

  if (
    !projects.value
      .map((p) => p.CSCPrjNum)
      .includes(to.params.projectNumber as string) &&
    singleProject.value?.CSCPrjNum !== to.params.projectNumber
  ) {
    const { getProjectByProjectNumber } = useProjectStore();

    await getProjectByProjectNumber(to.params.projectNumber as string);
  }
});
